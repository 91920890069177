

































































import Product from "@/models/Product"
import Options from "@/models/vue/Options"
import ProductService from "@/services/ProductService"
import {Component, Vue, Watch} from "vue-property-decorator"
import ProductComponent from "@/components/ProductComponent.vue"
import LangModule from "@/store/LangModule"
import { getModule } from "vuex-module-decorators"
import ProductDialog from "@/components/dialogs/ProductDialog.vue";

@Component({ components: { ProductComponent, ProductDialog } })
export default class SearchProductComponent extends Vue {

  lang: any = getModule(LangModule).lang
  products: Product[] = []
	product: Product = new Product()
	productDialog: boolean = false
	options: Options = new Options()
	page: number = 1
  pageCount: number = 0
  itemsPerPage: number = 12
  loading: boolean = false
  search: string = ""
  headers = []
  rules = [
    (v: string) => v.length > 2 ? true : this.lang.minimumLength
  ]
  totalItems: number = 0

  created() {
		this.refresh()
	}

  refresh() {
    ProductService.getProducts(this, this.products, this.page - 1, this.itemsPerPage, this.search, [], null)
  }

	openProductDialog(product: Product) {
		this.productDialog = true
		this.product = product
		this.product.quantity == 0 ? this.product.quantity = 1 : null
	}
  
  @Watch('loading')
	onLoadingChanged() {
		if (!this.loading) {
      this.pageCount = Math.round(this.totalItems / this.itemsPerPage)
		}
	}

	@Watch('page')
	onPageChanged() {
		this.refresh()
	}
  
}
